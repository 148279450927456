<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm contract-table contract-table-header">
            <thead v-if="aml">
                <tr class="table-warning">
                    <th colspan="2">
                        {{$t('aml.header.provider')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>{{$t('contract.header.name')}}:</th>
                    <td>CYRRUS FX, a.s.</td>
                </tr>
                <tr>
                    <th>{{$t('contract.header.address')}}:</th>
                    <td>Na Florenci 2116/15, 110 00 Praha 1</td>
                </tr>
                <tr>
                    <th>{{$t('contract.header.ic')}}:</th>
                    <td>28880293</td>
                </tr>
                <tr v-if="!aml">
                    <td colspan="2">{{$t('contract.header.registration')}}</td>
                </tr>
                <tr v-if="!aml">
                    <td>{{$t('contract.header.owner')}}:</td>
                    <td>{{$t('contract.header.ownerText')}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'FxHeader',
    props: {
        aml: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
