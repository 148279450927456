<template>
    <div>
        <div>
            <h5 v-if="!hideHeading">{{ $t('realOwners.text.heading') }}</h5>
            <i18n v-if="!showExpanded" path="realOwners.text.showHelp" tag="p">
                <a
                    href="#"
                    class="text-bold"
                    @click.prevent="isExpanded = !isExpanded"
                >
                    {{ $t('realOwners.text.showHelpContent') }}
                </a>
            </i18n>
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="in-out"
            >
                <div v-show="showExpanded">
                    <p>{{$t('realOwners.text.subheading')}}</p>
                    <contract-par
                        number="-"
                        :par-number-class="parNumberClass"
                    >
                        <i18n path="realOwners.text.list1" class="mb-1" tag="span">
                            <strong>{{ $t('realOwners.text.list1Content') }}</strong>
                        </i18n>
                    </contract-par>
                    <div class="px-md-3">
                        <contract-par
                            class="mt-3"
                            number="1."
                            :par-number-class="parNumberClass"
                        >
                            <strong>{{ $t('realOwners.text.type1') }}</strong>
                        </contract-par>
                        <contract-par
                            class="mt-3"
                            number="2."
                            :par-number-class="parNumberClass"
                        >
                            <i18n path="realOwners.text.type2" class="mb-1" tag="span">
                                <strong>{{ $t('realOwners.text.type2Content') }}</strong>
                            </i18n>
                            <contract-par
                                number="a)"
                                :text="$t('realOwners.text.type2a')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="b)"
                                :text="$t('realOwners.text.type2b')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="c)"
                                :text="$t('realOwners.text.type2c')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="d)"
                                :par-number-class="parNumberClass"
                            >
                                {{ $t('realOwners.text.type2d') }}
                                <contract-par
                                    number="i."
                                    :text="$t('realOwners.text.type2di')"
                                    :par-number-class="parNumberClass"
                                />
                                <contract-par
                                    number="ii."
                                    :text="$t('realOwners.text.type2dii')"
                                    :par-number-class="parNumberClass"
                                />
                                <contract-par
                                    number="iii."
                                    :text="$t('realOwners.text.type2diii')"
                                    :par-number-class="parNumberClass"
                                />
                            </contract-par>
                            <contract-par
                                number="e)"
                                :text="$t('realOwners.text.type2e')"
                                :par-number-class="parNumberClass"
                            />
                        </contract-par>
                        <contract-par
                            class="mt-3"
                            number="3."
                            :par-number-class="parNumberClass"
                        >
                            <i18n path="realOwners.text.type3" class="mb-1" tag="span">
                                <strong>{{ $t('realOwners.text.type3Content') }}</strong>
                            </i18n>
                            <contract-par
                                number="a)"
                                :text="$t('realOwners.text.type3a')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="b)"
                                :text="$t('realOwners.text.type3b')"
                                :par-number-class="parNumberClass"
                            />
                        </contract-par>
                        <contract-par
                            class="mt-3"
                            number="4."
                            :par-number-class="parNumberClass"
                        >
                            <strong>{{ $t('realOwners.text.type4') }}</strong>
                            <contract-par
                                number="a)"
                                :text="$t('realOwners.text.type4a')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="b)"
                                :text="$t('realOwners.text.type4b')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="c)"
                                :text="$t('realOwners.text.type4c')"
                                :par-number-class="parNumberClass"
                            />
                        </contract-par>
                        <contract-par
                            class="mt-3"
                            number="5."
                            :par-number-class="parNumberClass"
                        >
                            <i18n path="realOwners.text.type5" class="mb-1" tag="span">
                                <strong>{{ $t('realOwners.text.type5Content') }}</strong>
                            </i18n>
                            <contract-par
                                number="a)"
                                :text="$t('realOwners.text.type5a')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="b)"
                                :text="$t('realOwners.text.type5b')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="c)"
                                :text="$t('realOwners.text.type5c')"
                                :par-number-class="parNumberClass"
                            />
                        </contract-par>
                        <contract-par
                            class="mt-3"
                            number="6."
                            :par-number-class="parNumberClass"
                        >
                            <i18n path="realOwners.text.type6" class="mb-1" tag="span">
                                <strong>{{ $t('realOwners.text.type6Content') }}</strong>
                            </i18n>
                            <contract-par
                                number="a)"
                                :text="$t('realOwners.text.type6a')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="b)"
                                :text="$t('realOwners.text.type6b')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="c)"
                                :text="$t('realOwners.text.type6c')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="d)"
                                :text="$t('realOwners.text.type6d')"
                                :par-number-class="parNumberClass"
                            />
                            <contract-par
                                number="e)"
                                :text="$t('realOwners.text.type6e')"
                                :par-number-class="parNumberClass"
                            />
                        </contract-par>
                    </div>
                </div>
            </transition>
        </div>
        <p
            v-if="allowTruncate"
            class="my-2"
        >
            <b-btn
                v-if="isExpanded"
                variant="outline-primary"
                size="sm"
                @click.prevent="isExpanded = !isExpanded"
            >
                {{ $t('app.showLess') }}
            </b-btn>
        </p>
    </div>
</template>
<script>
import ContractPar from '../../Signature/Components/Contract/ContractPar.vue'
export default {
    name: 'RealOwnerText',
    components: {
        ContractPar
    },
    props: {
        parNumberClass: {
            type: String,
            required: false,
            default: ''
        },
        allowTruncate: {
            type: Boolean,
            required: false,
            default: false
        },
        hideHeading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            isExpanded: false
        }
    },
    computed: {
        showExpanded () {
            return this.isExpanded || !this.allowTruncate
        }
    }
}
</script>
