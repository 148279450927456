<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm contract-table contract-table-client">
            <thead>
                <tr class="table-warning">
                    <th colspan="6">
                        {{$t('aml.header.serviceRecipient')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.companyName')}}
                    </th>
                    <td colspan="2">
                        {{((isCompany) ? basicData.companyName : `${basicData.name} ${basicData.surname}`) }}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.domicile')}}
                    </th>
                    <td colspan="2">
                        {{$t(`countries.${basicData.domicile}`)}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.ic')}}
                    </th>
                    <td colspan="2">
                        {{basicData.ic}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.subjectOfBusiness')}}
                    </th>
                    <td colspan="2">
                        {{basicData.companyScope}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.companyAddress')}}
                    </th>
                    <td colspan="2">
                        {{`${basicData.addressStreet}, ${basicData.addressZip}, ${basicData.addressCity}`}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.realSubjectOfBusiness')}}
                    </th>
                    <td colspan="2">
                        {{basicData.companyRealScope}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.contactAddress')}}
                    </th>
                    <td colspan="2">
                        {{((basicData.postAddressActive) ? `${basicData.postAddressStreet}, ${basicData.postAddressZip}, ${basicData.postAddressCity}` : '---')}}</td>
                    <th>
                        {{$t('aml.clientHeader.riskActivity')}}
                    </th>
                    <td colspan="2">
                        {{$t(`riskActivities.riskActivity${basicData.companyRiskActivity}`)}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.country')}}
                    </th>
                    <td colspan="2">
                        {{$t(`countries.${basicData.addressCountry}`)}}</td>
                    <th>
                        {{$t('aml.clientHeader.legalForm')}}
                    </th>
                    <td colspan="2">
                        {{basicData.companyForm}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ClientHeader from './ClientHeader.vue'

export default {
    name: 'AmlClientHeader',
    extends: ClientHeader
}
</script>
