<template>
    <div class="container" v-if="sessionData">
        <h1>{{ $t('signature.heading') }}</h1>

        <div class="mt-4">
            <contract-preview
                :session-data="sessionData"
            />

            <div class="row mt-5">
                <div v-if="!externalAcquirer" class="col-12 col-md-6">
                    <div class="bg-white rounded shadow p-4">
                        <reference-form
                            :defaults="sessionData"
                            @save="onSaveReference"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0">
                    <loader-overlay
                        :loading="loading"
                    >
                        <contract-sign-form
                            v-if="!isSigned"
                            :clientType="clientType"
                            :executives="executives"
                            :signaturePhone="signaturePhone"
                            :reference="sessionData.reference"
                            @signed="onSigned"
                        />
                    </loader-overlay>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContractSignForm from './Components/ContractSignForm.vue'
import ContractPreview from './Components/ContractPreview.vue'
import ReferenceForm from './Components/ReferenceForm.vue'
import ViewBase from '../ViewBase.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Signature',
    extends: ViewBase,
    components: {
        ContractSignForm,
        ContractPreview,
        ReferenceForm
    },
    data () {
        return {
            loading: true,
            isSigned: false,
            isSent: false,
            showForm: false
        }
    },
    computed: {
        ...mapGetters([
            'externalAcquirer'
        ]),
        executives () {
            return ((this.sessionData && this.clientType === 'PO') ? (this.sessionData.companyPeople || []) : [])
        },
        signaturePhone () {
            return ((this.sessionData && this.clientType !== 'PO') ? this.sessionData.basicData.phone : undefined)
        }
    },
    mounted () {
        this.checkSignature()
            .then(() => {
                if (this.isSigned) {
                    this.onSigned()
                }
            })
    },
    methods: {
        async checkSignature () {
            this.loading = true
            try {
                await this.$api.clientSessionSignatures.checkSignature()
                this.isSigned = true
            } catch (error) {
                // nothing
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        },
        async onSigned () {
            await this.checkSignature()
            await this.$api.clientSession.closeSession()
            this.$emit('clear-session')
            this.$router.replace({
                name: 'SessionDataSent'
            })
        },
        onSaveReference (value) {
            this.saveSessionData(value)
        }
    }
}
</script>
