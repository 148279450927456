<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm contract-table contract-table-client">
            <thead v-if="aml">
                <tr class="table-warning">
                    <th colspan="6">
                        {{$t('aml.header.serviceRecipient')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        {{$t('contract.clientHeader.name')}}:
                    </th>
                    <td colspan="2">
                        {{((isCompany) ? basicData.companyName : `${basicData.name} ${basicData.surname}`) }}
                    </td>
                    <th>
                        {{$t('contract.clientHeader.phonePassword')}}:
                    </th>
                    <td colspan="2">
                        {{basicData.phonePassword}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('contract.clientHeader.address')}}:
                    </th>
                    <td colspan="2">
                        {{basicData.addressStreet}}, {{basicData.addressZip}}, {{basicData.addressCity}}
                    </td>
                    <th>
                        {{$t('contract.clientHeader.phone')}}:
                    </th>
                    <td colspan="2">
                        {{basicData.phone}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('contract.clientHeader.postAddress')}}:
                    </th>
                    <td colspan="2">
                        {{((basicData.postAddressActive) ? `${basicData.postAddressStreet}, ${basicData.postAddressZip}, ${basicData.postAddressCity}` : '---')}}</td>
                    <th>
                        {{$t('contract.clientHeader.email')}}:
                    </th>
                    <td colspan="2">
                        {{basicData.email}}
                    </td>
                </tr>
                <tr>
                    <th>{{$t('contract.clientHeader.rcic')}}:</th>
                    <td colspan="2">{{((!isPerson) ? basicData.ic : basicData.rc)}}</td>
                    <th>{{$t('contract.clientHeader.birthDate')}}:</th>
                    <td colspan="2">{{(!isCompany ) ? new Date(basicData.birthDate).toLocaleDateString() : '---'}}</td>
                </tr>
                <tr>
                    <th>{{$t('contract.clientHeader.citizenship')}}:</th>
                    <td colspan="2">{{(!isCompany) ? $t(`countries.${personalId.citizenship}`) : '---'}}</td>
                    <th>{{$t('contract.clientHeader.domicile')}}:</th>
                    <td colspan="2">{{$t(`countries.${basicData.domicile}`)}}</td>
                </tr>
                <tr>
                    <th>{{$t('contract.clientHeader.birthCity')}}:</th>
                    <td colspan="2">{{(!isCompany) ? personalId.birthCity : '---'}}</td>
                    <th>{{$t('contract.clientHeader.vat')}}:</th>
                    <td colspan="2">{{(!isPerson && basicData.vat) ? basicData.vat : '---'}}</td>
                </tr>
                <tr>
                    <th>{{$t('contract.clientHeader.documentNumber')}}:</th>
                    <td>{{(!isCompany) ? personalId.documentNumber : '---'}}</td>
                    <th>{{$t('contract.clientHeader.documentValidity')}}:</th>
                    <td>{{(!isCompany) ? new Date(personalId.documentValidity).toLocaleDateString() : '---'}}</td>
                    <th>{{$t('contract.clientHeader.documentIssuedBy')}}:</th>
                    <td>{{(!isCompany) ? personalId.documentIssuedBy : '---'}}</td>
                </tr>
                <tr v-if="isCompany">
                    <td colspan="6">
                        <table class="table table-bordered table-sm contract-table-owners" v-for="owner in owners" :key="owner._id">
                            <tbody>
                                <tr>
                                    <th>{{$t('contract.ownersHeader.name')}}:</th>
                                    <td>{{owner.name}} {{owner.surname}}</td>
                                    <th>{{$t('contract.ownersHeader.jobTitle')}}:</th>
                                    <td>{{owner.jobFunction}}</td>
                                    <th>{{$t('contract.clientHeader.birthDate')}}:</th>
                                    <td>{{new Date(owner.birthDate).toLocaleDateString()}}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('contract.clientHeader.documentNumber')}}:</th>
                                    <td>{{owner.documentNumber}}</td>
                                    <th>{{$t('contract.clientHeader.documentValidity')}}:</th>
                                    <td>{{new Date(owner.documentValidity).toLocaleDateString()}}</td>
                                    <th>{{$t('contract.clientHeader.documentIssuedBy')}}:</th>
                                    <td>{{owner.documentIssuedBy}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ClientHeader',
    props: {
        aml: {
            type: Boolean,
            required: false,
            default: false
        },
        clientType: {
            type: String,
            required: true
        },
        basicData: {
            type: Object,
            required: true
        },
        owners: {
            type: Array,
            required: false,
            default: () => []
        },
        personalId: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        isCompany () {
            return this.clientType === 'PO'
        },
        isPerson () {
            return this.clientType === 'FO'
        },
        isSelfEmployed () {
            return this.clientType === 'OS'
        }
    }
}
</script>
