<template>
    <div class="table-responsive">
        <table
            class="table table-bordered table-sm contract-table contract-table-brokers"
        >
            <thead>
                <tr class="table-warning">
                    <th colspan="6">
                        {{$t('aml.header.representedBy')}}
                    </th>
                </tr>
            </thead>
            <tbody
                v-for="broker in brokers"
                :key="broker.id"
            >
                <tr>
                    <th>{{$t('contract.contractContent.brokers.name')}}:</th>
                    <td>{{broker.name}} {{broker.surname}}</td>
                    <th>{{$t('contract.ownersHeader.jobTitle')}}:</th>
                    <td>{{broker.jobFunction}}</td>
                    <th>{{$t('contract.clientHeader.birthDate')}}:</th>
                    <td>{{new Date(broker.birthDate).toLocaleDateString()}}</td>
                </tr>
                <tr>
                    <th>{{$t('contract.clientHeader.documentNumber')}}:</th>
                    <td>{{broker.documentNumber}}</td>
                    <th>{{$t('contract.clientHeader.documentValidity')}}:</th>
                    <td>{{new Date(broker.documentValidity).toLocaleDateString()}}</td>
                    <th>{{$t('contract.clientHeader.documentIssuedBy')}}:</th>
                    <td>{{broker.documentIssuedBy}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BrokersTable from './BrokersTable.vue'

export default {
    name: 'AmlRepresentedBy',
    extends: BrokersTable
}
</script>
