<template>
    <div class="contract-par">
        <p
            class="contract-par-number mb-0"
            :class="parNumberClass"
        >
            {{number}}
        </p>
        <p class="contract-par-text mb-0">
            <slot>
                {{text}}
            </slot>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ContractPar',
    props: {
        number: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: false
        },
        parNumberClass: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
