<template>
    <div>
        <div class="contract-wrapper shadow">
            <div v-bar class="rounded d-flex justify-content-center">
                <div class="p-4 bg-cyrrus-gray-darker">
                    <div class="p-4 bg-white shadow mb-5">
                        <framework-contract
                            :session-data="sessionData"
                        />
                    </div>
                    <div class="p-4 bg-white shadow">
                        <aml-contract
                            :session-data="sessionData"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FrameworkContract from './Contract/FrameworkContract.vue'
import AmlContract from './Contract/AmlContract.vue'

export default {
    name: 'ContractPreview',
    props: {
        sessionData: {
            type: Object,
            required: true
        }
    },
    components: {
        FrameworkContract,
        AmlContract
    }
}
</script>
