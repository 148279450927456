<template>
    <div class="table-responsive">
        <contract-par
            number=""
            :text="$t('contract.contractContent.pars.5.2')"
        />

        <table class="table table-bordered table-sm contract-table contract-table-accounts">
            <thead>
                <tr>
                    <th>{{$t('contract.contractContent.bank.name')}}</th>
                    <th>{{$t('contract.contractContent.bank.currency')}}</th>
                    <th>{{$t('contract.contractContent.bank.accountNumber')}}</th>
                    <th>{{$t('contract.contractContent.bank.bankCode')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="account in accounts"
                    :key="account._id"
                >
                    <td>{{account.name}}</td>
                    <td>{{account.currency}}</td>
                    <td>{{((account.isForeign) ? account.iban : `${((account.accountNumberPrefix) ? `${account.accountNumberPrefix}-` : '')}${account.accountNumber}`)}}</td>
                    <td>{{((account.isForeign) ? account.swift : account.bankCode)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ContractPar from './ContractPar.vue'

export default {
    name: 'AccountsTable',
    components: {
        ContractPar
    },
    props: {
        accounts: {
            type: Array,
            required: true
        }
    }
}
</script>
