<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm contract-table contract-table-real-owners">
            <thead>
                <tr class="table-warning">
                    <th colspan="4">
                        {{$t('aml.header.realOwners')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2">
                        {{$t('aml.realOwnerCanBeIdentified')}}
                    </th>
                    <td colspan="2">
                        {{$t((!realOwnerUndeterminable) ? 'aml.yes' : 'aml.no')}}
                    </td>
                </tr>
            </tbody>
            <tbody v-for="owner in realOwners" :key="owner._id">
                <tr>
                    <th>{{$t('contract.contractContent.realOwners.name')}}</th>
                    <td>{{owner.name}} {{owner.surname}}</td>

                    <th>{{$t('contract.contractContent.realOwners.birthDate')}}</th>
                    <td>{{ new Date(owner.birthDate).toLocaleDateString() }}</td>
                </tr>
                <tr>
                    <th>{{$t('contract.contractContent.realOwners.address')}}</th>
                    <td>{{owner.addressStreet}}, {{owner.addressZip}}, {{owner.addressCity}}</td>

                    <th>{{$t('aml.clientHeader.country')}}</th>
                    <td>{{$t(`countries.${owner.addressCountry}`)}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.pep')}}</th>
                    <td>{{$t((owner.politician) ? 'aml.yes' : 'aml.no')}}</td>

                    <th>{{$t('aml.citizenship')}}</th>
                    <td>{{$t(`countries.${owner.citizenship}`)}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.isRealOwner')}}</th>
                    <td>{{$t((owner.isRealOwner) ? 'aml.yes' : 'aml.no')}}</td>

                    <th>{{$t('aml.isStatutory')}}</th>
                    <td>{{$t((owner.isStatutory) ? 'aml.yes' : 'aml.no')}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.relationshipDetail')}}</th>
                    <td>{{owner.relationshipDetail}}</td>

                    <th>{{$t('aml.statutoryType')}}</th>
                    <td>{{owner.statutoryType ? $t(`statutoryTypes.${owner.statutoryType}`) : ''}}</td>
                </tr>
            </tbody>
        </table>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AmlRealOwners',
    props: {
        realOwnerUndeterminable: {
            type: Boolean,
            required: true
        },
        realOwners: {
            type: Array,
            required: false,
            default: () => []
        }
    }
}
</script>
