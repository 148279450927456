<template>
    <div>
        <h2 class="text-center">{{$t('contract.contractHeading')}}</h2>
        <p class="text-italic text-center">{{$t('contract.beforeHeader')}}</p>

        <fx-header />

        <p class="text-italic text-center">{{$t('contract.afterHeader')}}</p>

        <client-header
            :client-type="clientType"
            :basic-data="basicData"
            :owners="owners"
            :personal-id="personalId"
        />

        <p class="text-italic text-center">{{$t('contract.afterClientHeader')}}</p>

        <brokers-table
            :client-type="clientType"
            :basic-data="basicData"
            :brokers="brokers"
            :owners="owners"
            :personal-id="personalId"
        />

        <accounts-table
            :accounts="accounts"
        />

        <h3 class="text-center">{{$t('contract.contractContent.headings.I')}}</h3>
        <contract-par
            v-for="item in getIterator(1,2)"
            :key="item.id"
            :number="item.id"
            :text="$t(`contract.contractContent.pars.1.${item.index}`)"
        />

        <h3 class="text-center">{{$t('contract.contractContent.headings.II')}}</h3>
        <contract-par
            v-for="item in getIterator(2,6)"
            :key="item.id"
            :number="item.id"
            :text="$t(`contract.contractContent.pars.2.${item.index}`)"
        />

        <h3 class="text-center">{{$t('contract.contractContent.headings.III')}}</h3>
        <contract-par
            v-for="item in getIterator(3,3)"
            :key="item.id"
            :number="item.id"
            :text="$t(`contract.contractContent.pars.3.${item.index}`)"
        />

        <h3 class="text-center">{{$t('contract.contractContent.headings.IV')}}</h3>
        <contract-par
            v-for="item in getIterator(4,7)"
            :key="item.id"
            :number="item.id"
            :text="$t(`contract.contractContent.pars.4.${item.index}`)"
        />

        <p>{{$t('contract.contractContent.legalInfo')}}</p>
    </div>
</template>

<script>
import ContractBase from './ContractBase.vue'

export default {
    name: 'FrameworkContract',
    extends: ContractBase
}
</script>
