<template>
    <div class="table-responsive">
        <table
            v-if="clientType === 2"
            class="table table-bordered table-sm contract-table-owners"
        >
            <thead>
                <tr class="table-warning">
                    <th colspan="6">
                        {{$t('aml.header.statutary')}}
                    </th>
                </tr>
            </thead>
            <tbody
                v-for="owner in owners"
                :key="owner._id"
            >
                <tr>
                    <th>{{$t('contract.contractContent.brokers.name')}}:</th>
                    <td>{{owner.name}} {{owner.surname}}</td>
                    <th>{{$t('contract.clientHeader.birthDate')}}:</th>
                    <td>{{new Date(owner.birthDate).toLocaleDateString()}}</td>
                    <th>{{$t('aml.type')}}</th>
                    <td>{{owner.jobFunction}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.address')}}</th>
                    <td>{{owner.addressStreet}}, {{owner.addressCity}}, {{owner.addressZip}}</td>
                    <th>{{$t('aml.clientHeader.country')}}</th>
                    <td>{{$t(`countries.${owner.addressCountry}`)}}</td>
                    <th>{{$t('aml.pep')}}</th>
                    <td>{{(owner.politician) ? $t('aml.yes') : $t('aml.no')}}</td>
                </tr>
            </tbody>
        </table>
        <slot></slot>
    </div>
</template>

<script>
import BrokersTable from './BrokersTable.vue'

export default {
    name: 'AmlStatutary',
    extends: BrokersTable
}
</script>
