<template>
    <p>
        <b-icon :icon="(state) ? 'check-square' : 'square'"></b-icon>
        {{text}}
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: 'ContractCheckbox',
    props: {
        text: {
            type: String,
            required: false,
            default: ''
        },
        state: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>
