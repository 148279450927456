<template>
    <div class="table-responsive">
        <contract-par
            number=""
            :text="$t('contract.contractContent.pars.5.1')"
        />

        <table
            v-if="!isCompany"
            class="table table-bordered table-sm contract-table contract-table-brokers"
        >
            <tbody>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.name')}}:</th>
                    <td colspan="2">{{basicData.name}} {{basicData.surname}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.birthCity')}}:</th>
                    <td colspan="2">{{personalId.birthCity}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.rc')}}:</th>
                    <td colspan="2">{{((!basicData.noRc) ? basicData.rc : new Date(basicData.birthDate).toLocaleDateString())}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.politician')}}:</th>
                    <td colspan="2">{{((basicData.politician) ? $t('app.yes') : $t('app.no'))}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.sex')}}:</th>
                    <td colspan="2">{{(personalId.sex === 'M') ? $t('contract.contractContent.brokers.man') : $t('contract.contractContent.brokers.woman')}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.citizenship')}}:</th>
                    <td colspan="2">{{personalId.citizenship}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.address')}}:</th>
                    <td colspan="6">{{basicData.addressStreet}}, {{basicData.addressZip}}, {{basicData.addressCity}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.phone')}}:</th>
                    <td colspan="2">{{basicData.phone}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.email')}}:</th>
                    <td colspan="2">{{basicData.email}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.documentNumber')}}:</th>
                    <td colspan="2">{{personalId.documentNumber}}</td>
                    <th>{{$t('contract.contractContent.brokers.documentValidity')}}:</th>
                    <td>{{new Date(personalId.documentValidity).toLocaleDateString()}}</td>
                    <th>{{$t('contract.contractContent.brokers.documentIssuedBy')}}:</th>
                    <td>{{personalId.documentIssuedBy}}</td>
                </tr>
            </tbody>
        </table>

        <div class="table-responsive" v-else>
            <table
                v-for="owner in owners"
                :key="owner.id"
                class="table table-bordered table-sm contract-table contract-table-brokers"
            >
                <tbody>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.name')}}:</th>
                        <td colspan="2">{{owner.name}} {{owner.surname}}</td>
                        <th colspan="2">{{$t('contract.contractContent.brokers.birthCity')}}:</th>
                        <td colspan="2">{{owner.birthCity}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.rc')}}:</th>
                        <td colspan="2">{{((!owner.noRc) ? owner.rc : new Date(owner.birthDate).toLocaleDateString())}}</td>
                        <th colspan="2">{{$t('contract.contractContent.brokers.politician')}}:</th>
                        <td colspan="2">{{((owner.politician) ? $t('contract.yes') : $t('contract.no'))}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.sex')}}:</th>
                        <td colspan="2">{{(owner.sex === 'M') ? $t('contract.contractContent.brokers.man') : $t('contract.contractContent.brokers.woman')}}</td>
                        <th colspan="2">{{$t('contract.contractContent.brokers.citizenship')}}:</th>
                        <td colspan="2">{{owner.citizenship}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.address')}}:</th>
                        <td colspan="6">{{owner.addressStreet}}, {{owner.addressZip}}, {{owner.addressCity}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.phone')}}:</th>
                        <td colspan="2">{{owner.phone}}</td>
                        <th colspan="2">{{$t('contract.contractContent.brokers.email')}}:</th>
                        <td colspan="2">{{owner.email}}</td>
                    </tr>
                    <tr>
                        <th colspan="2">{{$t('contract.contractContent.brokers.documentNumber')}}:</th>
                        <td colspan="2">{{owner.documentNumber}}</td>
                        <th>{{$t('contract.contractContent.brokers.documentValidity')}}:</th>
                        <td>{{new Date(owner.documentValidity).toLocaleDateString()}}</td>
                        <th>{{$t('contract.contractContent.brokers.documentIssuedBy')}}:</th>
                        <td>{{owner.documentIssuedBy}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <table
            v-for="broker in brokers"
            :key="broker.id"
            class="table table-bordered table-sm contract-table contract-table-brokers"
        >
            <tbody>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.name')}}:</th>
                    <td colspan="2">{{broker.name}} {{broker.surname}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.birthCity')}}:</th>
                    <td colspan="2">{{broker.birthCity}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.rc')}}:</th>
                    <td colspan="2">{{((!broker.noRc) ? broker.rc : new Date(broker.birthDate).toLocaleDateString())}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.politician')}}:</th>
                    <td colspan="2">{{((broker.politician) ? $t('contract.yes') : $t('contract.no'))}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.sex')}}:</th>
                    <td colspan="2">{{(broker.sex === 'M') ? $t('contract.contractContent.brokers.man') : $t('contract.contractContent.brokers.woman')}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.citizenship')}}:</th>
                    <td colspan="2">{{broker.citizenship}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.address')}}:</th>
                    <td colspan="6">{{broker.addressStreet}}, {{broker.addressZip}}, {{broker.addressCity}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.phone')}}:</th>
                    <td colspan="2">{{broker.phone}}</td>
                    <th colspan="2">{{$t('contract.contractContent.brokers.email')}}:</th>
                    <td colspan="2">{{broker.email}}</td>
                </tr>
                <tr>
                    <th colspan="2">{{$t('contract.contractContent.brokers.documentNumber')}}:</th>
                    <td colspan="2">{{broker.documentNumber}}</td>
                    <th>{{$t('contract.contractContent.brokers.documentValidity')}}:</th>
                    <td>{{new Date(broker.documentValidity).toLocaleDateString()}}</td>
                    <th>{{$t('contract.contractContent.brokers.documentIssuedBy')}}:</th>
                    <td>{{broker.documentIssuedBy}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ContractPar from './ContractPar.vue'

export default {
    name: 'BrokersTable',
    components: {
        ContractPar
    },
    props: {
        clientType: {
            type: String,
            required: true
        },
        basicData: {
            type: Object,
            required: true
        },
        owners: {
            type: Array,
            required: false,
            default: () => []
        },
        personalId: {
            type: Object,
            required: false,
            default: () => ({})
        },
        brokers: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        isCompany () {
            return this.clientType === 'PO'
        },
        isPerson () {
            return this.clientType === 'FO'
        },
        isSelfEmployed () {
            return this.clientType === 'OS'
        }
    }
}
</script>
