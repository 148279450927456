<template>
    <div>
        <h4>{{ $t('referenceForm.heading')}}</h4>
        <form-input-radio-group
            v-model="referenceOption"
            :options="referenceOptions"
            :validation="$v.referenceOption"

        />
        <form-input-textarea
            v-if="referenceOption"
            v-model="reference"
            :label="referenceLabel"
            :validation="$v.reference"
        />
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
let tm
export default {
    name: 'ReferenceForm',
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data () {
        return {
            referenceOption: this.defaults.referenceOption || null,
            reference: this.defaults.reference || null
        }
    },
    computed: {
        referenceOptions () {
            return [
                {
                    text: this.$t('referenceForm.referenceOptions.existingClient'),
                    value: 'existingClient'
                },
                {
                    text: this.$t('referenceForm.referenceOptions.iz'),
                    value: 'iz'
                },
                {
                    text: this.$t('referenceForm.referenceOptions.other'),
                    value: 'other'
                }
            ]
        },
        referenceLabel () {
            return this.$t(`referenceForm.referenceLabel.${this.referenceOption}`)
        },
        dataString () {
            return `${this.reference}${this.referenceOption}`
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.reference = this.defaults.reference || null
                this.referenceOption = this.defaults.referenceOption || null
            })
        },
        dataString () {
            this.$nextTick(() => this.saveData())
        }
    },
    mounted () {
        this.$v.$touch()
    },
    methods: {
        saveData () {
            clearTimeout(tm)
            tm = setTimeout(() =>
                this.$emit('save', { reference: this.reference, referenceOption: this.referenceOption })
            , 500)
        }
    },
    validations: {
        reference: {
            required
        },
        referenceOption: {
            required
        }
    }
}
</script>
